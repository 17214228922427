import axios from 'axios';
import global from '../../../components/Global.vue';

let base = global.serverSite + '/doartIot/doart-system';

// 分页查询产品型号信息
export const queryProductModelList = params => { return axios.post(base + '/productModel/queryProductModelList', params)};
// 获取所有产品线id和名称
export const getAllProductLineIdName = params => { return axios.post(base + '/productModel/getAllProductLineIdName', params)};
// 新增产品型号
export const addProductModel = params => { return axios.post(base + '/productModel/addProductModel', params)};
// 编辑产品型号
export const editProductModel = params => { return axios.post(base + '/productModel/editProductModel', params)};
// 删除产品型号
export const delProductModel = params => { return axios.post(base + '/productModel/delProductModel', params)};