<template>
    <div>
        <div class="panel-style">
            <el-form :inline="true" :model="searchForm" size="mini">
                <el-form-item label="产品线" prop="productModelCode">
                    <el-select v-model="searchForm.productLineId" clearable filterable placeholder="选择产品线">
                        <el-option
                            v-for="item in productLineSelect"
                            :key="item.productLineId"
                            :label="item.productLineName"
                            :value="item.productLineId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="产品型号名称" prop="productModelName">
                    <el-input class="input" v-model="searchForm.productModelName" placeholder="产品型号名称" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryTableData">查询</el-button>
                    <el-button type="primary" @click="openAdd">新增</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" max-height="550px" border size="small" stripe
                  :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="productLineName" label="产品线名称" width="150px"/>
                <el-table-column prop="name" label="产品型号名称"/>
                <el-table-column prop="code" label="产品型号编码" width="150px"/>
                <el-table-column prop="deviceSnRule" label="设备编码规则"/>
                <el-table-column prop="className" label="类名"/>
                <el-table-column prop="serverPort" label="采集服务器端口" width="120px" align="center"/>
                <el-table-column prop="updateByName" label="最后更新人" width="90px" align="center"/>
                <el-table-column prop="updateTime" label="最后更新时间" width="150px" align="center"/>
                <el-table-column width="80px" label="操作" align="center">
                    <template v-slot="scope">
                        <el-row>
                            <el-col :span="12">
                                <el-tooltip v-delTabIndex effect="dark" content="编辑" placement="top">
                                    <i class="el-icon-edit option-btn-normal" @click="openEdit(scope.row)"/>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="12">
                                <el-tooltip v-delTabIndex effect="dark" content="删除" placement="top">
                                    <i class="el-icon-delete option-btn-normal" style="color: #F56C6C"
                                       @click="delProductModel(scope.row.id)"/>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableTotal"
                    :current-page.sync="searchForm.pageNum"
                    :page-sizes="[10, 50, 100]"
                    :page-size="searchForm.pageSize"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"/>
            </div>
            <el-dialog title="新增产品型号" :visible.sync="addVisible" v-if="addVisible" center
                   :close-on-click-modal="false" width="500px" top="0">
                <el-form :model="addForm" :rules="formRules" ref="addForm" size="mini" label-width="170px" :inline="true">
                    <el-row>
                        <el-form-item label="产品线：" prop="productLineId">
                            <el-select
                                v-model="addForm.productLineId"
                                clearable
                                filterable
                                placeholder="请选择产品线"
                                class="optItem">
                                <el-option
                                    v-for="item in productLineSelect"
                                    :key="item.productLineId"
                                    :label="item.productLineName"
                                    :value="item.productLineId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="产品型号名称：" prop="name">
                            <el-input v-model="addForm.name" placeholder="请输入产品型号名称" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="产品型号编码：" prop="code">
                            <el-input v-model="addForm.code" placeholder="请输入产品型号编码" autocomplete="off"
                                      class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="设备序列号编码规则：" prop="deviceSnRule">
                            <el-input v-model="addForm.deviceSnRule" placeholder="正则表达式" autocomplete="off"
                                      class="optItem" maxlength="50"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="类名：" prop="className">
                            <el-input v-model="addForm.className" placeholder="请输入类名" autocomplete="off"
                                      class="optItem" maxlength="100"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="采集服务器端口：" prop="serverPort">
                            <el-input v-model="addForm.serverPort" placeholder="请输入采集服务器端口" autocomplete="off"
                                      class="optItem" maxlength="10"/>
                        </el-form-item>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" size="mini" @click="addSubmit" :loading="addLoading">确认</el-button>
                    <el-button size="mini" @click="addVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="编辑产品型号" :visible.sync="editVisible" v-if="editVisible" center
                   :close-on-click-modal="false" width="500px" top="0">
                <el-form :model="editForm" :rules="formRules" ref="editForm" size="mini" label-width="170px" :inline="true">
                    <el-row>
                        <el-form-item label="产品线：" prop="productLineId">
                            <el-select
                                v-model="editForm.productLineId"
                                clearable
                                filterable
                                placeholder="请选择产品线"
                                disabled
                                class="optItem">
                                <el-option
                                    v-for="item in productLineSelect"
                                    :key="item.productLineId"
                                    :label="item.productLineName"
                                    :value="item.productLineId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="产品型号名称：" prop="name">
                            <el-input v-model="editForm.name" placeholder="请输入产品型号名称" autocomplete="off"
                                  class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="产品型号编码：" prop="code">
                            <el-input v-model="editForm.code" autocomplete="off" disabled class="optItem"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="设备序列号编码规则：" prop="deviceSnRule">
                            <el-input v-model="editForm.deviceSnRule" placeholder="正则表达式" autocomplete="off"
                                      class="optItem" maxlength="50"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="类名：" prop="className">
                            <el-input v-model="editForm.className" placeholder="请输入类名" autocomplete="off"
                                  class="optItem" maxlength="100"/>
                        </el-form-item>
                    </el-row>
                    <el-row class="rowItem">
                        <el-form-item label="采集服务器端口：" prop="serverPort">
                            <el-input v-model="editForm.serverPort" autocomplete="off" disabled class="optItem"/>
                        </el-form-item>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="editSubmit" size="mini" :loading="editLoading">确认</el-button>
                    <el-button size="mini" @click="editVisible = false">取消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import * as productModelApi from "@/api/sys/product/productModelApi";
import message from "@/utils/message";
import global from "@/components/Global";

export default {

    name: "product-model",
    data() {

        return {

            searchForm: {

                pageNum: 1,
                pageSize: 10,
                productModelName: '',
                productLineId: '',
            },
            productLineSelect: [],
            listLoading: false,
            tableData: [],
            tableTotal: 0,
            addLoading: false,
            addVisible: false,
            addForm: {

                name: '',
                code: '',
                productLineId: '',
                deviceSnRule: '',
                className: '',
                serverPort: ''
            },
            formRules: {

                productLineId: [
                    {required: true, message: '请选择产品线', trigger: ['blur', 'change']},
                ],
                name: [
                    {required: true, message: '请输入产品型号名称', trigger: ['blur', 'change']},
                    { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: ['blur', 'change'] },
                    { pattern: /^\S*$/, message: '注意空格', trigger: ['blur', 'change'] }
                ],
                code: [
                    {required: true, message: '请输入产品型号编码', trigger: ['blur', 'change']},
                    { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: ['blur', 'change'] },
                    { pattern: /^[A-Za-z0-9]+[A-Za-z0-9-_]*[A-Za-z0-9]+$/, message: '只能为数字、英文字母、下划线和中划线，且不可以下划线和中划线开始或结束', trigger: ['blur', 'change'] }
                ],
                deviceSnRule: [
                    {required: true, message: '请输入产品型号规则', trigger: ['blur', 'change']}
                ],
                className: [
                    {required: true, message: '请输入类名', trigger: ['blur', 'change']}
                ],
                serverPort: [
                    {pattern: /^[1-9]\d*$/, message: '正整数', trigger: ['blur', 'change']}
                ]
            },
            editLoading: false,
            editVisible: false,
            editForm: {

                id: '',
                name: '',
                code: '',
                productLineId: '',
                deviceSnRule: '',
                className: '',
                serverPort: ''
            }
        };
    },
    methods: {

        openAdd() {

            this.addVisible = true;
            this.addForm.code = '';
            this.addForm.name = '';
            this.addForm.productLineId = '';
            this.addForm.deviceSnRule = '';
            this.addForm.className = '';
            this.addForm.serverPort = '';
        },
        openEdit(e) {

            this.editVisible = true;
            this.editForm.code = e.code;
            this.editForm.name = e.name;
            this.editForm.id = e.id;
            this.editForm.productLineId = e.productLineId;
            this.editForm.deviceSnRule = e.deviceSnRule;
            this.editForm.className = e.className;
            this.editForm.serverPort = e.serverPort;
        },
        addSubmit() {

            this.$refs["addForm"].validate((valid) => {

                if (valid) {

                    this.addLoading = true;
                    productModelApi.addProductModel(this.addForm).then((res) => {

                        this.addLoading = false;
                        if (res.code === global.response_status_success_obj) {

                            this.addVisible = false;
                            this.getTableData();
                            message.success(res.msg)
                        } else {

                            message.error(res.msg)
                        }

                    }).catch(err => {

                        console.error(err);
                    });
                } else {

                    return false;
                }
            });
        },
        editSubmit() {

            this.$refs["editForm"].validate((valid) => {

                if (valid) {

                    this.editLoading = true;
                    productModelApi.editProductModel(this.editForm).then((res) => {

                        this.editLoading = false;

                        if (res.code === global.response_status_success_obj) {

                            this.editVisible = false;
                            this.getTableData();
                            message.success(res.msg)
                        } else {

                            message.error(res.msg)
                        }

                    }).catch(err => {

                        console.error(err);
                    });
                } else {

                    return false;
                }
            });
        },
        delProductModel(id) {

            message.confirm("是否要删除该产品型号记录？", false, "warning", ()=>{

                productModelApi.delProductModel({id: id}).then((res) => {

                    if (res.code === global.response_status_success_obj) {

                        this.getTableData();
                        message.success(res.msg)
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err=> {

                    message.error('请求失败，请重试');
                    console.error(err);
                });
            })
        },

        /**
         * 信息查询.
         */
        queryTableData() {

            this.searchForm.pageNum = 1;
            this.getTableData();
        },
        /**
         * 初始化页面
         */
        initPage() {

            this.searchForm.pageNum = 1;
            this.getTableData();
            this.getAllProductLineIdName();
        },
        /**
         * 查询页面数据.
         */
        getTableData() {

            this.listLoading = true;
            productModelApi.queryProductModelList(this.searchForm).then((res) => {

                this.listLoading = false;
                if (res.code === global.response_status_success_obj) {

                    this.tableTotal = res.data.total;
                    this.tableData = res.data.result;
                }
            }).catch(err => {

                console.error(err);
                message.error('请求失败，请重试');
                this.listLoading = false;
            });
        },
        getAllProductLineIdName() {

            productModelApi.getAllProductLineIdName().then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.productLineSelect = res.data;
                }
            }).catch(err => {

                console.error(err);
                message.error('请求失败，请重试');
            });
        },
        /**
         * 处理分页事件
         * @param val 页码
         */
        handleCurrentChange(val) {

            this.searchForm.pageNum = val;
            this.getTableData();
        },
        handleSizeChange(val) {

            this.searchForm.pageSize = val;
            this.searchForm.pageNum = 1;
            this.getTableData();
        }
    },
    mounted() {

        this.initPage();
    }
}
</script>

<style scoped>

.input {

    width: 247px;
}

.pagination {

    margin-top: 15px;
    text-align: right;
}

.optItem {

    width: 250px;
}

.rowItem {

    margin-top: 10px;
}

.option-btn-normal {

    font-size: 18px;
    color: #409EFF;
    cursor: pointer;
}
</style>
